import "./src/styles/uikit.theme.less"
import "./src/styles/vindicia.less"
import "./src/styles/custom-template.less"
import "./src/styles/fonts.css"
import "lazysizes"
import "lazysizes/plugins/parent-fit/ls.parent-fit"
import "lazysizes/plugins/bgset/ls.bgset"
import "lazysizes/plugins/unveilhooks/ls.unveilhooks"

// gatsby-browser.js

import * as React from "react"
import { PreviewStoreProvider } from "gatsby-source-prismic"

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
)
